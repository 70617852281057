import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as text from '../styles/text.module.css';
import * as themes from '../styles/themes.module.css';
import {capitalize, colorVariants, themesToOptions} from '../utils';
import * as css from './Heading.module.css';

export const headingTags = {
  xxxl: 'h1',
  xxl: 'h1',
  xl: 'h1',
  l: 'h2',
  m: 'h3',
  s: 'h4',
  xs: 'h5',
  xxs: 'h5',
};

const HeadingWithImage = ({
  size = 'xl',
  children,
  variant,
  align = 'center',
  className,
  theme,
  src,
  imageAlign = 'left',
  containerWidth = 100,
  imageMaxWidth = 100,
  imageHeight,
  imageWidth,
  paddingTop,
  paddingBottom,
}) => {
  const Tag = `${headingTags[size] || 'span'}`;

  const imageStyles = {
    margin: '1rem 1rem',
    maxWidth: `${imageMaxWidth}%`,
    height: imageHeight ? `${imageHeight}px` : undefined,
    width: imageWidth ? `${imageWidth}px` : undefined,
  };

  const getAlignment = align => {
    switch (align) {
      case 'left':
        return 'flex-start';
      case 'center':
        return 'center';
      case 'right':
        return 'flex-end';
      default:
        return 'center';
    }
  };

  const headerStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: getAlignment(align),
    flexDirection: imageAlign === 'top' ? 'column' : 'row',
    margin: 'auto',
    width: `${containerWidth}%`,
    paddingTop: '20px',
    paddingBottom: '20px',
  };

  return (
    <div
      className={classnames(css.root, className, css[align], themes[theme])}
      style={{overflow: 'auto', paddingTop, paddingBottom}}
    >
      <Tag className={classnames(text[`heading${capitalize(size)}`], text[variant])} style={headerStyles}>
        <img
          src={src}
          alt={' '}
          style={{
            ...imageStyles,
            display: imageAlign === 'left' || imageAlign === 'top' ? 'inline-block' : 'none',
          }}
        />
        {children}
        <img
          src={src}
          alt={' '}
          style={{
            ...imageStyles,
            display: imageAlign === 'right' ? 'inline-block' : 'none',
          }}
        />
      </Tag>
    </div>
  );
};

HeadingWithImage.propTypes = {
  size: PropTypes.oneOf(Object.keys(headingTags)),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  variant: PropTypes.oneOf(colorVariants),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  className: PropTypes.string,
  theme: PropTypes.oneOf(themesToOptions(themes)),
  block: PropTypes.bool,
  src: PropTypes.string,
  imageAlign: PropTypes.oneOf(['left', 'right', 'top']),
  containerWidth: PropTypes.number,
  imageMaxWidth: PropTypes.number,
  imageHeight: PropTypes.number,
  imageWidth: PropTypes.number,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
};

export default HeadingWithImage;
