import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import {colorVariants} from '../utils';
import * as css from './Price.module.css';

export const Price = ({className, price, type, variant, ...otherProps}) => {
  const classes = classnames(css.root, className, css[variant]);
  return (
    <div className={classes} {...otherProps}>
      <div className={css.borderBox}>
        <div className={css.boxRow}>
          <div className={css.borderTopLeft} />
          <span className={classnames(css.type, css[variant])}>{type}</span>
          <div className={css.borderTopRight} />
        </div>
        <div className={css.boxRow}>
          <div className={css.borderLeft} />
          <span className={css.price}>{`$${price}`}</span>
          <div className={css.borderRight} />
        </div>
        <div className={css.borderBottom} />
      </div>
    </div>
  );
};

export const PriceRow = ({children, variant, disclaimer}) => {
  return (
    <div className={classnames(css.priceRow)}>
      {React.Children.map(children, child => {
        return React.cloneElement(child, {
          variant,
          className: classnames(child.props.className),
        });
      })}
      {disclaimer && <div className={css.disclaimer}>{disclaimer}</div>}
    </div>
  );
};

Price.interactiveProps = {
  variant: {
    type: 'select',
    options: colorVariants,
  },
};

Price.propTypes = {
  className: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  type: PropTypes.string,
  variant: PropTypes.oneOf(colorVariants),
};

PriceRow.interactiveProps = {
  variant: {
    type: 'select',
    options: colorVariants,
  },
};

PriceRow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  variant: PropTypes.oneOf(colorVariants),
  disclaimer: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
