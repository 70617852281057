import React, {useRef, useEffect} from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import {useUID} from 'react-uid';

import * as themes from '../styles/themes.module.css';
import {themesToOptions, pickVariantWithTheme, colorVariants, capitalize} from '../utils';
import {initSlider} from '../utils/slider';
import * as css from './Slider.module.css';

import '@glidejs/glide/dist/css/glide.core.min.css';

export const Slider = ({
  theme,
  variant,
  children,
  perPage = 1,
  spacing = 'm',
  height,
  showControls,
  autoplay,
  className,
  itemClassName,
}) => {
  const uid = useUID();
  const id = `slider-${uid}`;

  const ref = useRef();
  useEffect(() => {
    const autoplayTimer = autoplay === true ? 1.8 : autoplay;
    const selector = `#${id}`;
    const glide = initSlider(selector, {
      perPage,
      autoplay: autoplay && autoplayTimer,
    });

    return () => {
      glide.destroy();
    };
  }, [id, perPage, autoplay, spacing, showControls]);

  return (
    <div
      id={id}
      ref={ref}
      className={classnames('glide', css.root, className, themes[theme], css[variant || pickVariantWithTheme(theme)], {
        [css[`spacing${capitalize(spacing)}`]]: spacing,
      })}
    >
      <div className={'glide__track'} data-glide-el="track">
        <ul className="glide__slides">
          {React.Children.map(children, child => (
            <div
              className={classnames(css.item, itemClassName, 'glide__slide', {
                [css[`spacing${capitalize(spacing)}`]]: spacing,
              })}
              style={{height}}
            >
              {React.cloneElement(child, {
                variant: variant || pickVariantWithTheme(theme),
                theme: theme,
              })}
            </div>
          ))}
        </ul>
      </div>
      {showControls && (
        <div data-glide-el="controls">
          <button
            className={classnames(css.prev, 'glide__arrow', 'glide__arrow--prev')}
            data-glide-dir="<"
            title="Prev"
          ></button>
          <button
            className={classnames(css.next, 'glide__arrow', 'glide__arrow--next')}
            data-glide-dir=">"
            title="Next"
          ></button>
        </div>
      )}

      <div className={classnames('glide__bullets', css.dots)} data-glide-el="controls[nav]">
        {React.Children.map(children, (_, i) => (
          <button key={i} className={classnames('glide__bullet', css.dot)} data-glide-dir={`=${i}`}></button>
        ))}
      </div>
    </div>
  );
};

Slider.interactiveProps = {
  theme: {
    type: 'select',
    options: themesToOptions(themes),
  },
  showControls: {type: 'bool'},
  autoplay: {
    type: 'select',
    options: [false, 1, 2, 3, 4, 5, 6],
    suffix: 'sec',
  },
  spacing: {
    type: 'select',
    options: [false, 's', 'm', 'l', 'xl', 'xxl', 'xxxl'],
  },
};

Slider.propTypes = {
  theme: PropTypes.oneOf(Slider.interactiveProps.theme.options),
  variant: PropTypes.oneOf(colorVariants),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  perPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  spacing: PropTypes.oneOf(Slider.interactiveProps.spacing.options),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showControls: PropTypes.bool,
  autoplay: PropTypes.number,
  className: PropTypes.string,
  itemClassName: PropTypes.string,
};

export default Slider;
