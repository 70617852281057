import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import ChiefLogo from './ChiefLogo';
import * as css from './NavigationBar.module.css';

export const NavigationBar = ({left, right, className}) => {
  const classes = classnames(css.root, className);
  return (
    <header className={classes}>
      <h1 className={css.chiefLogoMobile}>
        <ChiefLogo height={24} />
      </h1>
      <input className={css.sideMenu} type="checkbox" id="side-menu" />
      <label className={css.hamburgerMenuIcon} htmlFor="side-menu">
        <span></span>
      </label>
      <nav>
        {React.Children.map(left, child => {
          return React.cloneElement(child, {
            className: css.leftMenu,
          });
        })}
        <h1 className={css.chiefLogo}>
          <ChiefLogo height={24} />
        </h1>
        {React.Children.map(right, child => {
          return React.cloneElement(child, {
            className: css.rightMenu,
          });
        })}
      </nav>
    </header>
  );
};

export const NavRow = ({children, className}) => {
  const classes = classnames(css.navRow, className);
  return (
    <ul className={classes}>
      {React.Children.map(children, (child, index) => {
        return <li className={css.navItem}>{child}</li>;
      })}
    </ul>
  );
};

NavigationBar.propTypes = {
  left: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  right: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};

NavRow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};
