import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as themes from '../styles/themes.module.css';
import {colorVariants, pickVariantWithTheme, themesToOptions} from '../utils';
import Heading, {headingTags} from './Heading';
import * as css from './TextWithImage.module.css';

const idFormatting = heading => {
  return heading.length ? heading.replace(/ /g, '-') : '';
};

const TextWithImage = ({
  alt,
  children,
  extraLineHeight = 0,
  heading,
  headingSize = 'm',
  headingVariant,
  image,
  imageHeight,
  imageMaxWidthPercent,
  imagePosition,
  imageWidth,
  padding,
  reverse,
  textBlockMaxWidth,
  theme = 'green',
  widthImage = 50,
  widthText = 50,
  withLine,
}) => {
  const classes = classnames(css.root, css[theme], themes[theme], {
    [css.reverse]: reverse,
    [css.withLine]: withLine,
  });

  const customPadding = padding ? `${padding}px` : undefined;
  const xlPadding = imagePosition === 'center' ? '0' : 'var(--spacing-xxl)';
  const maxWidthPercentXl = `${textBlockMaxWidth ? `${textBlockMaxWidth}%` : `600px`}`;
  const maxWidthPercentL = `${textBlockMaxWidth ? `${textBlockMaxWidth}%` : `500px`}`;
  const extraLineHeightPixels = `${extraLineHeight}px`;
  const imageAlignSelf = imagePosition === 'top' ? 'flex-start' : 'auto';

  return (
    <div
      className={classes}
      style={{
        '--custom-padding': customPadding,
        '--xlarge-image-padding': xlPadding,
      }}
    >
      <div
        className={css.text}
        style={{
          ...(reverse ? {flex: `0 0 ${widthText}%`} : {}),
        }}
      >
        {heading && (
          <Heading
            size={headingSize}
            id={idFormatting(heading)}
            variant={headingVariant || pickVariantWithTheme(theme)}
          >
            {heading}
          </Heading>
        )}
        <div
          className={css.body}
          style={{
            '--max-width-percent-xl': maxWidthPercentXl,
            '--max-width-percent-l': maxWidthPercentL,
            '--extra-line-height': extraLineHeightPixels,
          }}
        >
          {children}
        </div>
      </div>
      <div
        className={css.image}
        style={{
          ...(reverse ? {flex: `0 0 ${widthImage}%`} : {}),
          alignSelf: imageAlignSelf,
        }}
      >
        <img
          src={image}
          alt={alt}
          style={{
            maxWidth: imageMaxWidthPercent,
            width: imageWidth,
            height: imageHeight,
          }}
        />
      </div>
    </div>
  );
};

TextWithImage.interactiveProps = {
  theme: {
    type: 'select',
    options: themesToOptions(themes),
  },
  headingSize: {
    type: 'select',
    options: Object.keys(headingTags),
  },
  headingVariant: {
    type: 'select',
    options: colorVariants,
  },
  reverse: {type: 'bool'},
  withLine: {type: 'bool'},
};

TextWithImage.propTypes = {
  heading: PropTypes.string,
  headingSize: PropTypes.oneOf(TextWithImage.interactiveProps.headingSize.options),
  headingVariant: PropTypes.oneOf(TextWithImage.interactiveProps.headingVariant.options),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  alt: PropTypes.string,
  image: PropTypes.string.isRequired,
  imageHeight: PropTypes.number,
  imageMaxWidthPercent: PropTypes.number,
  imagePosition: PropTypes.oneOf(['top', 'center']),
  imageWidth: PropTypes.number,
  padding: PropTypes.number,
  reverse: PropTypes.bool,
  textBlockMaxWidth: PropTypes.number,
  theme: PropTypes.oneOf(TextWithImage.interactiveProps.theme.options),
  widthImage: PropTypes.number,
  widthText: PropTypes.number,
  withLine: PropTypes.bool,
};

export default TextWithImage;
