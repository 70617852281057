import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as text from '../styles/text.module.css';
import {colorVariants} from '../utils';
import * as css from './Datum.module.css';

const Datum = ({data, description, className, variant, compact, stack, divider}) => {
  const classes = classnames(css.root, className, {
    [css.stack]: stack,
    [css.compact]: compact,
    [css.divider]: divider,
  });
  return (
    <div className={classes}>
      <span className={classnames(css.data, text.headingXxl, text[variant])}>{data}</span>
      <div className={css.text}>
        <p>{description}</p>
      </div>
    </div>
  );
};

Datum.interactiveProps = {
  variant: {
    type: 'select',
    options: colorVariants,
  },
};

Datum.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  description: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf(colorVariants),
  compact: PropTypes.bool,
  stack: PropTypes.bool,
  divider: PropTypes.bool,
};

export default Datum;
