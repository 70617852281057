import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as text from '../styles/text.module.css';
import * as themes from '../styles/themes.module.css';
import {capitalize, colorVariants, themesToOptions} from '../utils';
import * as css from './Heading.module.css';

export const headingTags = {
  xxxl: 'h1',
  xxl: 'h1',
  xl: 'h1',
  l: 'h2',
  m: 'h3',
  s: 'h4',
  xs: 'h5',
  xxs: 'h5',
};

const Heading = ({size = 'xl', children, variant, align, className, theme, block, id}) => {
  const Tag = `${headingTags[size] || 'span'}`;
  return (
    <div
      id={id}
      className={classnames(css.root, className, css[align], themes[theme], {
        [css.block]: block,
      })}
    >
      <Tag className={classnames(text[`heading${capitalize(size)}`], text[variant])}>{children}</Tag>
    </div>
  );
};

Heading.propTypes = {
  size: PropTypes.oneOf(Object.keys(headingTags)),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  variant: PropTypes.oneOf(colorVariants),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  className: PropTypes.string,
  theme: PropTypes.oneOf(themesToOptions(themes)),
  block: PropTypes.bool,
};

export default Heading;
