import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as themes from '../styles/themes.module.css';
import {themesToOptions, pickVariantWithTheme} from '../utils';
import Slider from './Slider';
import * as css from './Testimonials.module.css';

const Testimonials = ({className, children, theme = 'green', carousel, column}) => {
  const classes = classnames(css.root, css[theme], themes[theme], {
    [css.column]: column,
  });
  return carousel ? (
    <Slider
      perPage={1}
      autoplay={3}
      theme={theme}
      itemClassName={css.carouselTestimonialItem}
      className={css.carouselTestimonial}
    >
      {children}
    </Slider>
  ) : (
    <div className={classes}>
      {React.Children.map(children, (child, index) => {
        return (
          <div className={css.item}>
            {React.cloneElement(child, {
              variant: pickVariantWithTheme(theme),
            })}
            {column && index != children.length - 1 ? <hr className={classnames(css.divider)} /> : null}
          </div>
        );
      })}
    </div>
  );
};

Testimonials.interactiveProps = {
  theme: {
    type: 'select',
    options: themesToOptions(themes),
  },
  carousel: {type: 'bool'},
};

Testimonials.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  theme: PropTypes.oneOf(Testimonials.interactiveProps.theme.options),
  carousel: PropTypes.bool,
  column: PropTypes.bool,
};

export default Testimonials;
