import React from 'react';

import classnames from 'classnames';
import {size} from 'lodash';
import PropTypes from 'prop-types';
import {style, width} from 'styled-system';

import Chevron from '../assets/chevron-right.svg';
import {colorVariants} from '../utils';
import * as css from './Button.module.css';
import Spacer from './Spacer.js';

export const Button = ({
  className,
  href,
  target,
  priority = 'primary',
  variant,
  onClick,
  children,
  width,
  small,
  download,
  style,
}) => {
  const classes = classnames(css.root, css[priority], css[variant], className, {
    [css.small]: small,
  });
  return href ? (
    <a
      className={classes}
      target={target}
      rel={target == '_blank' ? 'noopener noreferrer' : null}
      style={{width, ...style}}
      onClick={onClick}
      download={download}
      href={href}
    >
      <span />
      {children}
      <Chevron className={css.icon} />
    </a>
  ) : (
    <button className={classes} style={{width}} onClick={onClick}>
      <span />
      {children}
      <Chevron className={css.icon} />
    </button>
  );
};

export const ButtonRow = ({className, style, variant, children, verticalSpacingTop, verticalSpacingBottom}) => {
  return (
    <>
      <Spacer size={verticalSpacingTop} />
      <div className={classnames(css.buttonRow, className)} style={style}>
        {React.Children.map(children, child => {
          return React.cloneElement(child, {
            variant: variant || child.props.variant,
            className: classnames(child.props.className, css.buttonRowItem),
          });
        })}
      </div>
      <Spacer size={verticalSpacingBottom} />
    </>
  );
};

Button.interactiveProps = {
  variant: {
    type: 'select',
    options: colorVariants,
  },
};

Button.propTypes = {
  variant: PropTypes.oneOf(colorVariants),
  className: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  priority: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  width: PropTypes.number,
  small: PropTypes.bool,
  download: PropTypes.bool,
  style: PropTypes.object,
};

export default Button;
