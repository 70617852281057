import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as themes from '../styles/themes.module.css';
import {themesToOptions, pickVariantWithTheme, colorVariants, capitalize} from '../utils';
import * as css from './Row.module.css';

export const Row = ({
  theme,
  variant,
  height,
  grid,
  children,
  spacing = 'm',
  verticalSpacing,
  noGrow,
  itemFlex,
  itemAsWrapper = false,
  noSpacingTop,
  noSpacingBottom,
  align,
}) => (
  <div
    className={classnames(css.root, themes[theme], css[variant], css[align], {
      [css[`spacing${capitalize(spacing)}`]]: spacing,
      [css[`verticalSpacing${capitalize(verticalSpacing)}`]]: verticalSpacing,
      [css.noSpacingTop]: noSpacingTop,
      [css.noSpacingBottom]: noSpacingBottom,
      [css.grid]: grid,
    })}
  >
    {React.Children.map(children, child => {
      return (
        <RowItem
          spacing={spacing}
          noGrow={noGrow}
          height={height}
          itemFlex={itemFlex}
          isWrapper={itemAsWrapper}
          variant={variant || pickVariantWithTheme(theme)}
        >
          {child}
        </RowItem>
      );
    })}
  </div>
);

const RowItem = ({spacing = 'm', noGrow, height, itemFlex, isWrapper, variant, children}) => (
  <div
    className={classnames(
      css.item,
      {
        [css[`spacing${capitalize(spacing)}`]]: spacing,
        [css.noGrow]: noGrow,
      },
      isWrapper && children.props.className,
    )}
    style={{height, flex: itemFlex}}
  >
    {isWrapper
      ? React.Children.map(children.props.children, child =>
          React.cloneElement(child, {
            variant,
          }),
        )
      : React.cloneElement(children, {
          variant: children.type === 'function' ? variant : null,
        })}
  </div>
);

Row.interactiveProps = {
  theme: {
    type: 'select',
    options: themesToOptions(themes),
  },
  variant: {type: 'select', options: colorVariants},
  spacing: {
    type: 'select',
    options: [false, 's', 'm', 'l', 'xl', 'xxl', 'xxxl'],
  },
  verticalSpacing: {
    type: 'select',
    options: ['s', 'm', 'l', 'xl', 'xxl', 'xxxl'],
  },
  height: {type: 'number'},
};

Row.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  grid: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  spacing: PropTypes.oneOf(Row.interactiveProps.spacing.options),
  verticalSpacing: PropTypes.oneOf(Row.interactiveProps.spacing.options),
  noGrow: PropTypes.bool,
  itemFlex: PropTypes.string,
  noSpacingTop: PropTypes.bool,
  noSpacingBottom: PropTypes.bool,
  align: PropTypes.string,
  theme: PropTypes.oneOf(Row.interactiveProps.theme.options),
  variant: PropTypes.oneOf(Row.interactiveProps.variant.options),
};

export default Row;
