import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import {useUID} from 'react-uid';

import * as themes from '../styles/themes.module.css';
import {themesToOptions} from '../utils';
import * as css from './Video.module.css';

const youtubeConfig = ({loop, autoPlay, controls}) => ({
  playerVars: {
    controls: controls ? 1: 0,
    autoplay: autoPlay ? 1 : 0,
    loop: loop ? 1 : 0,
    modestbranding: 1,
    rel: 0,
    playsinline: 0,
  },
});

const wistiaConfig = ({controls, loop, playerId, autoPlay}) => ({
  playerId,
  options: {
    autoPlay,
    controlsVisibleOnLoad: controls ? 1 : 0,
    endVideoBehavior: loop ? 'loop' : 'default',
  },
});

const PlayIcon = () => (
  <div className={css.playIconWrapper}>
    <div className={css.playIcon} />
  </div>
);

export const Video = ({url, poster, framed, muted, loop, controls, theme = 'green', className, ...otherProps}) => {
  const playerId = useUID();
  const isYoutube = url.includes('youtube.com');
  const isWistia = url.includes('wistia.com');
  return (
    <div
      className={classnames(css.root, themes[theme], className, {
        [css.framed]: framed,
      })}
      {...otherProps}
    >
      <div className={css.container}>
        <ReactPlayer
          muted={muted}
          light={poster}
          className={css.reactPlayer}
          url={url}
          playIcon={<PlayIcon />}
          config={{
            youtube: youtubeConfig({controls, loop, autoPlay: !!poster}),
            wistia: wistiaConfig({controls, loop, playerId, autoPlay: !!poster}),
          }}
        />
      </div>
    </div>
  );
};

Video.interactiveProps = {
  theme: {
    type: 'select',
    options: themesToOptions(themes),
  },
};

Video.propTypes = {
  url: PropTypes.string,
  poster: PropTypes.string,
  framed: PropTypes.bool,
  muted: PropTypes.bool,
  loop: PropTypes.bool,
  controls: PropTypes.bool,
  theme: PropTypes.oneOf(Video.interactiveProps.theme.options),
  className: PropTypes.string,
};

export default Video;
