import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as themes from '../styles/themes.module.css';
import {capitalize, colorVariants, pickVariantWithTheme, themesToOptions} from '../utils';
import Heading from './Heading';
import Image from './Image';
import {InlayFromEdge} from './Inlay';
import * as css from './ListWithHeading.module.css';

const ListWithHeading = ({
  heading,
  headingSize = 'xl',
  headingVariant,
  description,
  cta,
  image,
  imageWidth,
  imagePosition,
  roundImage,
  children,
  reverse,
  inlay,
  fullWidth,
  widthPercent,
  textAlign,
  inlayHeight,
  theme = 'blackToDarkgreen',
  className,
}) => {
  const classes = classnames(css.root, className, css[theme], themes[theme], {
    [css.withImage]: image,
    [css.reverse]: reverse,
    [css.withInlay]: inlay,
    [css.customWidth]: fullWidth || !!widthPercent,
  });

  return (
    <div className={classes} style={{'--external-width-percent': `${widthPercent ?? 100}%`}}>
      <div
        className={classnames(css.text, {
          [css[`textAlign${capitalize(textAlign)}`]]: textAlign,
        })}
      >
        {heading && (
          <Heading
            className={classnames(css.title, css.titleWide, {
              [css[`textAlign${capitalize(textAlign)}`]]: textAlign,
            })}
            size={headingSize}
            variant={headingVariant || pickVariantWithTheme(theme)}
          >
            {heading}
          </Heading>
        )}
        {description && (
          <p
            className={classnames(css.description, {
              [css[`textAlign${capitalize(textAlign)}`]]: textAlign,
            })}
          >
            {description}
          </p>
        )}
        <div
          className={classnames(css.content, css[headingVariant || pickVariantWithTheme(theme)], {
            [css[`textAlign${capitalize(textAlign)}`]]: textAlign,
          })}
        >
          {children}
        </div>
        {cta &&
          React.cloneElement(cta, {
            variant: pickVariantWithTheme(theme),
          })}
      </div>
      {image && (
        <>
          <div className={css.image} style={{flexBasis: imageWidth}}>
            <Image
              src={image}
              inlay={inlay ? (reverse ? 'left' : 'right') : false}
              roundTopLeft={!reverse && roundImage}
              roundTopRight={reverse && roundImage}
              roundBottomLeft={!reverse && roundImage}
              roundBottomRight={reverse && roundImage}
              imagePosition={imagePosition}
              height={roundImage ? '80%' : '100%'}
            />
          </div>
          {heading && (
            <Heading
              className={classnames(css.title, css.titleNarrow)}
              size={headingSize}
              variant={headingVariant || pickVariantWithTheme(theme)}
            >
              {heading}
            </Heading>
          )}
        </>
      )}
      {inlay && !image && (
        <>
          <InlayFromEdge
            style={{flexBasis: imageWidth}}
            height={inlayHeight}
            from={reverse ? 'left' : 'right'}
            className={css.inlay}
            id="list-with-heading"
          />
        </>
      )}
    </div>
  );
};

ListWithHeading.interactiveProps = {
  theme: {
    type: 'select',
    options: themesToOptions(themes),
  },
  headingSize: {
    type: 'select',
    options: ['xxxl', 'xxl', 'xl', 'l', 'm'],
  },
  headingVariant: {
    type: 'select',
    options: colorVariants,
  },
  reverse: {type: 'bool'},
  roundImage: {type: 'bool'},
};

ListWithHeading.propTypes = {
  heading: PropTypes.string,
  headingSize: PropTypes.oneOf(ListWithHeading.interactiveProps.headingSize.options),
  headingVariant: PropTypes.oneOf(ListWithHeading.interactiveProps.headingVariant.options),
  description: PropTypes.string,
  cta: PropTypes.element,
  image: PropTypes.string,
  imageWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  imageHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  imagePosition: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  roundImage: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  reverse: PropTypes.bool,
  inlay: PropTypes.bool,
  fullWidth: PropTypes.bool,
  widthPercent: PropTypes.number,
  textAlign: PropTypes.string,
  inlayHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  theme: PropTypes.oneOf(ListWithHeading.interactiveProps.theme.options),
  className: PropTypes.string,
};

export default ListWithHeading;
