import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as text from '../styles/text.module.css';
import * as themes from '../styles/themes.module.css';
import {themesToOptions} from '../utils';
import * as css from './Body.module.css';

const Body = ({size, children, className, block, theme}) => {
  const classes = classnames(css.root, className, text[size], themes[theme], {
    [css.block]: block,
  });
  return block ? (
    <div className={classes}>
      <p>{children}</p>
    </div>
  ) : (
    <p className={classes}>{children}</p>
  );
};

Body.propTypes = {
  size: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  block: PropTypes.bool,
  theme: PropTypes.oneOf(themesToOptions(themes)),
};

export default Body;
