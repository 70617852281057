import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import {colorVariants} from '../utils';
import Avatar from './Avatar';
import Button from './Button';
import Image from './Image';
import * as css from './Quote.module.css';

export const Quote = ({
  children,
  name,
  title,
  company,
  avatar,
  logo,
  alt,
  large,
  className,
  reverse,
  variant,
  href,
  label,
}) => {
  const classes = classnames(css.root, className, css[variant], {
    [css.withAvatar]: avatar,
    [css.reverse]: reverse,
    [css.large]: large,
  });
  const hasAuthor = name || title || company;
  const image = avatar ? (
    <Avatar image={avatar} alt={alt} className={css.quoteAvatar} />
  ) : logo ? (
    <Image src={logo} alt={alt} className={css.logo} height={130} />
  ) : (
    <></>
  );
  return avatar ? (
    <div className={classes}>
      {image}
      <div className={css.text}>
        <div className={css.quote}>{children}</div>
        {name && <h4 className={css.name}>{name}</h4>}
        {title && <h5 className={css.title}>{`${title}, ${company}`}</h5>}
      </div>
    </div>
  ) : (
    <div className={classes}>
      {image}
      <div className={css.quote}>{children}</div>
      {hasAuthor && <div className={css.divider}></div>}
      {name && <h4 className={css.name}>{name}</h4>}
      {title && <h5 className={css.title}>{title}</h5>}
      {company && <h5 className={css.company}>{company}</h5>}
      {href && (
        <Button href={href} className={css.cta} variant={variant}>
          {label || href}
        </Button>
      )}
    </div>
  );
};

Quote.interactiveProps = {
  variant: {
    type: 'select',
    options: colorVariants,
  },
};

Quote.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  name: PropTypes.string,
  title: PropTypes.string,
  company: PropTypes.string,
  avatar: PropTypes.string,
  logo: PropTypes.string,
  alt: PropTypes.string,
  large: PropTypes.bool,
  className: PropTypes.string,
  reverse: PropTypes.bool,
  variant: PropTypes.oneOf(colorVariants),
  href: PropTypes.string,
  label: PropTypes.string,
};

export default Quote;
