import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as text from '../styles/text.module.css';
import * as themes from '../styles/themes.module.css';
import {themesToOptions, pickVariantWithTheme, colorVariants} from '../utils';
import Heading, {headingTags} from './Heading';
import * as css from './Membership.module.css';

const Membership = ({
  heading,
  headingSize = 'm',
  children,
  theme = 'green',
  variant,
  className,
  inlays = true,
  ...otherProps
}) => {
  const classes = classnames(css.root, className, themes[theme], {
    [css.withInlays]: inlays,
  });
  return (
    <div className={classes} {...otherProps}>
      {inlays && <hr />}
      {heading && (
        <Heading size={headingSize} variant={variant || pickVariantWithTheme(theme)}>
          {heading}
        </Heading>
      )}
      {React.Children.map(children, child => {
        return React.cloneElement(child, {
          theme,
          variant: variant || pickVariantWithTheme(theme),
        });
      })}
      {inlays && <hr />}
    </div>
  );
};

Membership.interactiveProps = {
  theme: {
    type: 'select',
    options: themesToOptions(themes),
  },
  inlays: {
    type: 'bool',
  },
  headingSize: {
    type: 'select',
    options: Object.keys(headingTags),
  },
  variant: {
    type: 'select',
    options: colorVariants,
  },
};

Membership.propTypes = {
  heading: PropTypes.string,
  headingSize: PropTypes.oneOf(Membership.interactiveProps.headingSize.options),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  theme: PropTypes.oneOf(Membership.interactiveProps.theme.options),
  variant: PropTypes.oneOf(Membership.interactiveProps.variant.options),
  className: PropTypes.string,
  inlays: PropTypes.bool,
};

export default Membership;
