import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as spacer from '../styles/spacer.module.css';
import * as themes from '../styles/themes.module.css';

export const Spacer = ({size, theme}) => {
  return <div className={classnames(spacer[size], themes[theme])} />;
};

Spacer.propTypes = {
  size: PropTypes.oneOf(['s', 'm', 'l', 'xl', 'xxl', 'xxxl']),
};

export default Spacer;
