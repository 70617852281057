import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as text from '../styles/text.module.css';
import * as themes from '../styles/themes.module.css';
import {capitalize, colorVariants, pickVariantWithTheme, themesToOptions} from '../utils';
import * as css from './Form.module.css';
import Heading from './Heading';
import {InlayFromEdge} from './Inlay';

export const Form = ({
  children,
  theme = 'green',
  heading,
  headingSize,
  headingVariant,
  description,
  bodySize,
  reverse,
  inlay,
  className,
  ...otherProps
}) => {
  return (
    <form
      className={classnames(css.root, themes[theme], {
        [css.reverse]: reverse,
        [css.withInlay]: inlay,
      })}
      {...otherProps}
    >
      <div className={css.formGroup}>
        {heading && (
          <Heading size={headingSize} variant={headingVariant || pickVariantWithTheme(theme)}>
            {heading}
          </Heading>
        )}
        {description && (
          <div
            className={classnames({
              [text[`body${capitalize(bodySize)}`]]: bodySize,
            })}
          >
            {description}
          </div>
        )}
        {React.Children.map(children, child => {
          return React.cloneElement(child, {
            className: classnames(child.props.className, css.item),
            variant: pickVariantWithTheme(theme),
          });
        })}
      </div>
      {inlay && <InlayFromEdge from={reverse ? 'left' : 'right'} className={css.inlay} id="form" />}
    </form>
  );
};

Form.interactiveProps = {
  theme: {
    type: 'select',
    options: themesToOptions(themes),
  },
  headingSize: {
    type: 'select',
    options: ['s', 'm', 'l', 'xl', 'xxl', 'xxxl'],
  },
  headingVariant: {
    type: 'select',
    options: colorVariants,
  },
  bodySize: {
    type: 'select',
    options: ['s', 'm', 'l', 'xl'],
  },
  inlay: {type: 'bool'},
  reverse: {type: 'bool'},
};

Form.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  theme: PropTypes.oneOf(Form.interactiveProps.theme.options),
  heading: PropTypes.string,
  headingSize: PropTypes.oneOf(Form.interactiveProps.headingSize.options),
  headingVariant: PropTypes.oneOf(Form.interactiveProps.headingVariant.options),
  description: PropTypes.string,
  bodySize: PropTypes.oneOf(Form.interactiveProps.bodySize.options),
  reverse: PropTypes.bool,
  inlay: PropTypes.bool,
  className: PropTypes.string,
};

export default Form;
