import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as text from '../styles/text.module.css';
import * as themes from '../styles/themes.module.css';
import {themesToOptions, pickVariantWithTheme, capitalize, colorVariants} from '../utils';
import Heading from './Heading';
import * as css from './ShortDescription.module.css';

const ShortDescription = ({
  headingSize = 'm',
  bodySize,
  headingVariant,
  heading,
  children,
  theme = 'green',
  className,
  inlay = true,
  reverse,
}) => {
  const classes = classnames(css.root, className, themes[theme], {
    [css.reverse]: reverse,
  });
  return (
    <div className={classes}>
      {heading && (
        <Heading className={css.heading} size={headingSize} variant={headingVariant || pickVariantWithTheme(theme)}>
          {heading}
        </Heading>
      )}
      {inlay && <hr />}
      <div
        className={classnames(css.text, {
          [text[`body${capitalize(bodySize)}`]]: bodySize,
        })}
      >
        {React.Children.map(children, child => {
          return React.cloneElement(child, {
            variant: pickVariantWithTheme(theme),
          });
        })}
      </div>
    </div>
  );
};

ShortDescription.interactiveProps = {
  theme: {
    type: 'select',
    options: themesToOptions(themes),
  },
  headingSize: {
    type: 'select',
    options: ['s', 'm', 'l', 'xl', 'xxl', 'xxxl'],
  },
  headingVariant: {
    type: 'select',
    options: colorVariants,
  },
  bodySize: {
    type: 'select',
    options: ['s', 'm', 'l', 'xl'],
  },
  inlay: {type: 'bool'},
  reverse: {type: 'bool'},
};

ShortDescription.propTypes = {
  theme: PropTypes.oneOf(ShortDescription.interactiveProps.theme.options),
  inlay: PropTypes.bool,
  reverse: PropTypes.bool,
  headingSize: PropTypes.oneOf(ShortDescription.interactiveProps.headingSize.options),
  bodySize: PropTypes.oneOf(ShortDescription.interactiveProps.bodySize.options),
  headingVariant: PropTypes.oneOf(ShortDescription.interactiveProps.headingVariant.options),
  heading: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};

export default ShortDescription;
