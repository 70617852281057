import React, {useState} from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import {useUIDSeed} from 'react-uid';

import * as text from '../styles/text.module.css';
import {colorVariants} from '../utils';
import * as css from './Choice.module.css';

export const Choice = ({name, value, label, options = [], onChange, isMulti, className, variant, ...otherProps}) => {
  const seed = useUIDSeed();
  const [currentValue, setCurrentValue] = useState(value || isMulti ? [] : undefined);

  const isChecked = optValue => (isMulti ? currentValue.includes(optValue) : currentValue === optValue);

  const onOptionChange = e => {
    const {value} = e.target;
    let newValue;

    if (isMulti) {
      newValue = currentValue.includes(value) ? currentValue.filter(i => i !== value) : currentValue.concat(value);
    } else {
      newValue = value;
    }
    setCurrentValue(newValue);
    onChange && onChange(name, newValue, e);
  };

  return (
    <div className={classnames(css.root, className, css[variant])}>
      <label className={classnames(css.label)}>{label || name}</label>
      <div className={css.group}>
        {options.map((option, index) => {
          const optValue = option.value || option;
          const optLabel = option.label || option;
          return (
            <div className={css.item} key={index}>
              <input
                id={seed(index)}
                type={isMulti ? 'checkbox' : 'radio'}
                name="name"
                onChange={onOptionChange}
                value={optValue}
                checked={isChecked(optValue)}
              />
              <label htmlFor={seed(index)}>{optLabel}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

Choice.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.oneOf(colorVariants),
};

export default Choice;
