import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as css from './Avatar.module.css';

const Avatar = ({alt, image, className}) => {
  const classes = classnames(css.root, className);
  return (
    <div className={classes}>
      <img src={image} alt={alt} />
    </div>
  );
};

Avatar.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
};

export default Avatar;
