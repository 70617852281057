import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import ChiefLogo from './ChiefLogo';
import * as css from './Footer.module.css';
import Subscribe from './Subscribe';

const Footer = ({className, onFormSubmit, success, subscribe}) => {
  return (
    <footer className={css.root}>
      <ChiefLogo height={24} style={{alignSelf: 'flex-start', marginBottom: 10}} />
      <div className={css.section}>
        {subscribe && (
          <div className={css.subscribeSection}>
            <p>Subscribe to Chief in Brief for the latest in thought leadership.</p>
            <Subscribe success={success} onSubmit={onFormSubmit} />
          </div>
        )}

        <nav className={classnames(css.menu, {[css.left]: !subscribe})}>
          <ul>
            <li>
              <a href="#">Explore Membership</a>
            </li>
            <li>
              <a href="#">Member Site</a>
            </li>
            <li>
              <a href="#">Careers</a>
            </li>
          </ul>
        </nav>
      </div>
      <hr className={css.divider} />
      <div className={css.section}>
        <div className={css.contact}>
          <p>
            For press inquiries <a href="#">press@chief.com</a>
          </p>
          <p>
            For general inquiries <a href="#">hello@chief.com</a>
          </p>
        </div>
        <nav className={css.legalMenu}>
          <ul>
            <li>
              <a href="#">Privacy Policy</a>
            </li>
            <li>
              <a href="#">Terms of Service</a>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  onFormSubmit: PropTypes.func,
  success: PropTypes.bool,
  subscribe: PropTypes.bool,
};

export default Footer;
