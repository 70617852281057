import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as themes from '../styles/themes.module.css';
import {themesToOptions, pickVariantWithTheme, colorVariants} from '../utils';
import Heading, {headingTags} from './Heading';
import * as css from './People.module.css';
import Promotional from './Promotional';

const People = ({
  eyebrow,
  eyebrowVariant,
  heading,
  headingSize = 'm',
  headingVariant,
  className,
  people,
  children,
  theme = 'green',
  reverse = 'false',
}) => {
  const classes = classnames(css.root, css[theme], themes[theme], {
    [css.reverse]: reverse,
  });
  return children ? (
    <div className={classes}>
      <div className={css.half}>
        {people &&
          React.cloneElement(people, {
            variant: pickVariantWithTheme(theme),
          })}
      </div>
      <div className={classnames(css.half, css.content)}>
        {eyebrow && (
          <Promotional size={'m'} variant={eyebrowVariant || pickVariantWithTheme(theme)}>
            {eyebrow}
          </Promotional>
        )}
        {heading && (
          <Heading size={headingSize} variant={headingVariant || pickVariantWithTheme(theme)}>
            {heading}
          </Heading>
        )}
        <div className={css.text}> {children}</div>
      </div>
    </div>
  ) : (
    <div className={classes}>
      {people &&
        React.cloneElement(people, {
          variant: pickVariantWithTheme(theme),
          large: true,
        })}
    </div>
  );
};

People.interactiveProps = {
  theme: {
    type: 'select',
    options: themesToOptions(themes),
  },
  headingSize: {
    type: 'select',
    options: Object.keys(headingTags),
  },
  headingVariant: {
    type: 'select',
    options: colorVariants,
  },
  reverse: {
    type: 'bool',
  },
};

People.propTypes = {
  eyebrow: PropTypes.string,
  eyebrowVariant: PropTypes.oneOf(People.interactiveProps.headingVariant.options),
  heading: PropTypes.string,
  headingSize: PropTypes.oneOf(People.interactiveProps.headingSize.options),
  headingVariant: PropTypes.oneOf(People.interactiveProps.headingVariant.options),
  className: PropTypes.string,
  people: PropTypes.element,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  theme: PropTypes.oneOf(People.interactiveProps.theme.options),
  reverse: PropTypes.bool,
};

export default People;
