import React, {useEffect} from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import {useUID} from 'react-uid';

import {initAccordion} from '../utils/accordion';
import * as css from './Accordion.module.css';

export const Accordion = ({className, children}) => {
  const id = 'accordion-' + useUID();

  useEffect(() => {
    const destroy = initAccordion(`#${id}`);
    return destroy;
  }, []);
  return (
    <div className={classnames(css.root, className)} id={id}>
      {children}
    </div>
  );
};

Accordion.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

/** Hello **/
