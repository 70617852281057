import React from 'react';

import PropTypes from 'prop-types';

import Chief from '../assets/chief.svg';

const ChiefLogo = ({height, style}) => {
  return <Chief height={height} style={style} />;
};

ChiefLogo.propTypes = {
  height: PropTypes.number.isRequired,
};

export default ChiefLogo;
