import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as text from '../styles/text.module.css';
import {colorVariants} from '../utils';
import * as css from './Promotional.module.css';

const promotionalTags = {
  xl: 'h3',
  l: 'h4',
  m: 'h5',
  s: 'h6',
};

const Promotional = ({size = 'm', children, variant, align, className, block}) => {
  const Tag = `${promotionalTags[size] || 'span'}`;
  const _size = size.charAt(0).toUpperCase() + size.slice(1);

  return (
    <div
      className={classnames(css.root, className, css[align], {
        [css.block]: block,
      })}
    >
      <Tag className={classnames(text[`promo${_size}`], text[variant])}>{children}</Tag>
    </div>
  );
};

Promotional.propTypes = {
  size: PropTypes.oneOf(['s', 'm', 'l', 'xl']),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  variant: PropTypes.oneOf(colorVariants),
  align: PropTypes.bool,
  className: PropTypes.string,
  block: PropTypes.bool,
};

export default Promotional;
