export const initAccordion = (selector = '', options) => {
  const root = document.querySelector(selector);

  if (!root) {
    console.error('Invalid selector');
    return false;
  }
  const allLi = root.querySelectorAll('li');
  if (!allLi.length) {
    console.error('Accordion expects a list of `li` elements, none found');
  }
  const attr = 'data-expanded';

  // toggle function
  const toggle = e => {
    const selected = e.target.closest('li');
    // clear all expanded except selected one
    [].forEach.call(allLi, li => {
      li !== selected && li.removeAttribute(attr);
    });

    //expand it
    selected.hasAttribute(attr)
      ? selected.removeAttribute(attr)
      : selected.setAttribute(attr, 'true');
  };

  [].forEach.call(allLi, function (li) {
    li.addEventListener('click', toggle);
  });

  return () => {
    [].forEach.call(allLi, function (li) {
      li.removeEventListener('click', toggle);
    });
  };
};
