import Glide from '@glidejs/glide';

export const initSlider = (selector, {perPage = 1, autoplay = 2} = {}) => {
  const gliderOptions = {
    type: 'carousel',
    startAt: 0,
    perView: perPage,
    gap: 0,
    breakpoints: {
      1200: {
        perView: perPage,
      },
      990: {
        perView: perPage === 1 ? perPage : 2,
      },
      700: {
        perView: perPage === 1 ? perPage : 1,
      },
    },
    autoplay: autoplay ? autoplay * 1000 : false,
    hoverpause: false,
  };

  return new Glide(selector, gliderOptions).mount();
};
