// extracted by mini-css-extract-plugin
export var black = "ListWithHeading-module--black--8wlOq";
export var content = "ListWithHeading-module--content--QT6vi";
export var customWidth = "ListWithHeading-module--customWidth--EFGmi";
export var description = "ListWithHeading-module--description--HZHIJ";
export var gold = "ListWithHeading-module--gold--olUoB";
export var goldDark = "ListWithHeading-module--goldDark--gwsWS";
export var goldLight = "ListWithHeading-module--goldLight--DDfZR";
export var image = "ListWithHeading-module--image--Djahx";
export var inlay = "ListWithHeading-module--inlay--Hdckn";
export var reverse = "ListWithHeading-module--reverse--obt23";
export var root = "ListWithHeading-module--root--M7-O9";
export var text = "ListWithHeading-module--text--RWsF8";
export var textAlignCenter = "ListWithHeading-module--textAlignCenter--EOaax";
export var textAlignLeft = "ListWithHeading-module--textAlignLeft--02twB";
export var textAlignRight = "ListWithHeading-module--textAlignRight--iURCZ";
export var title = "ListWithHeading-module--title--Z9d2l";
export var titleNarrow = "ListWithHeading-module--titleNarrow--F3dUy";
export var titleWide = "ListWithHeading-module--titleWide---nHWB";
export var white = "ListWithHeading-module--white--WaPkL";
export var withImage = "ListWithHeading-module--withImage--sjjdR";
export var withInlay = "ListWithHeading-module--withInlay--X9pwh";