import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as themes from '../styles/themes.module.css';
import {themesToOptions, pickVariantWithTheme} from '../utils';
import * as css from './Image.module.css';
import {InlayFromEdge} from './Inlay';

export const Image = ({
  src,
  alt,
  framed,
  height,
  width,
  imageWidth,
  inlay,
  round,
  imagePosition,
  theme,
  className,
  roundTopLeft,
  roundTopRight,
  roundBottomLeft,
  roundBottomRight,
  children,
  ...otherProps
}) => {
  return (
    <figure
      style={{height, width}}
      className={classnames(css.root, themes[theme], className, {
        [css.framed]: framed,
        [css.withInlay]: inlay,
        [css.withInlayFromLeft]: inlay == 'left',
        [css.withInlayFromRight]: inlay == 'right',
      })}
      {...otherProps}
    >
      {children && (
        <div className={css.children}>
          {React.Children.map(children, child =>
            React.cloneElement(child, {
              variant: pickVariantWithTheme(theme),
            }),
          )}
        </div>
      )}

      {inlay ? (
        <>
          <InlayFromEdge
            className={css.inlay}
            from={inlay}
            image={src}
            height={isNaN(height) ? undefined : height}
            imageWidth={isNaN(imageWidth) ? undefined : Math.min(720, imageWidth)}
            id="image"
          />
          {src && (
            <img
              style={{objectPosition: imagePosition}}
              className={css.imageNarrowViewport}
              src={src}
              alt={alt || ' '}
            />
          )}
        </>
      ) : (
        <img
          className={css.image}
          src={src}
          alt={alt || ' '}
          style={{
            objectPosition: imagePosition,
            borderTopLeftRadius: roundTopLeft === true ? 210 : roundTopLeft,
            borderTopRightRadius: roundTopRight === true ? 210 : roundTopRight,
            borderBottomLeftRadius: roundBottomLeft === true ? 210 : roundBottomLeft,
            borderBottomRightRadius: roundBottomRight === true ? 210 : roundBottomRight,
          }}
        />
      )}
    </figure>
  );
};

Image.interactiveProps = {
  theme: {
    type: 'select',
    options: themesToOptions(themes),
  },
  height: {
    type: 'number',
  },
};

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  framed: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inlay: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  round: PropTypes.bool,
  imagePosition: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  theme: PropTypes.oneOf(Image.interactiveProps.theme.options),
  className: PropTypes.string,
  roundTopLeft: PropTypes.bool,
  roundTopRight: PropTypes.bool,
  roundBottomLeft: PropTypes.bool,
  roundBottomRight: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Image;
