import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import {colorVariants} from '../utils';
import Avatar from './Avatar';
import * as css from './AvatarCard.module.css';

export const AvatarCard = ({name, title, company, bio, image, alt, className, variant, urlName, url}) => {
  const classes = classnames(css.root, className, css[variant]);
  return (
    <div className={classes}>
      <Avatar image={image} alt={alt} />
      {name && <h4 className={css.name}>{name}</h4>}
      <div className={css.profile}>
        {title && <div>{title}</div>}
        {company && <div>{company}</div>}
        {url && (
          <a className={css.website} href={url}>
            {urlName || url}
          </a>
        )}
      </div>
      {bio && (
        <>
          <div className={css.divider}></div>
          <p className={css.bio}>“{bio}”</p>
        </>
      )}
    </div>
  );
};

export const AvatarList = ({className, style, variant, children, large = false}) => {
  return (
    <div className={classnames(css.avatarList, className)} style={style}>
      {React.Children.map(children, child => {
        return React.cloneElement(child, {
          variant,
          className: classnames(child.props.className, {
            [css.large]: large,
          }),
        });
      })}
    </div>
  );
};

AvatarCard.interactiveProps = {
  variant: {
    type: 'select',
    options: colorVariants,
  },
};

AvatarCard.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  company: PropTypes.string,
  bio: PropTypes.string,
  image: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  urlName: PropTypes.string,
  url: PropTypes.string,
  variant: PropTypes.oneOf(colorVariants),
};
