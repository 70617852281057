import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import {useUID} from 'react-uid';

import {colorVariants} from '../utils';
import * as css from './Input.module.css';

export const Input = ({
  name,
  label,
  value,
  placeholder,
  type = 'text',
  onChange,
  className,
  variant,
  max,
  ...otherProps
}) => {
  const id = useUID();

  const onInputChange = e => {
    onChange && onChange(name, e.target.value, e);
  };

  return (
    <div className={classnames(css.root, className, css[variant])}>
      <input
        id={id}
        className={css.input}
        name={name}
        value={value}
        type={type}
        placeholder={placeholder || name || ' '}
        onChange={onInputChange}
        max={max || type === 'date' ? '9999-12-31' : null}
        {...otherProps}
      />
      <label className={css.label} htmlFor={id}>
        {label || name}
      </label>
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  variant: PropTypes.oneOf(colorVariants),
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Input;
