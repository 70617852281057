// extracted by mini-css-extract-plugin
export var black = "Quote-module--black--51cfF";
export var company = "Quote-module--company--K+XvW";
export var cta = "Quote-module--cta--pyMWd";
export var divider = "Quote-module--divider--7c9bd";
export var goldDark = "Quote-module--goldDark--N+1Gx";
export var goldLight = "Quote-module--goldLight--M8-xi";
export var large = "Quote-module--large--gGDWu";
export var logo = "Quote-module--logo--X4fBE";
export var name = "Quote-module--name--Cmpro";
export var quote = "Quote-module--quote--IOaBv";
export var quoteAvatar = "Quote-module--quoteAvatar--RV3DW";
export var quoteColumn = "Quote-module--quoteColumn--Uc2D8";
export var quoteRow = "Quote-module--quoteRow--OhdeC";
export var reverse = "Quote-module--reverse--K10rj";
export var root = "Quote-module--root--I18Nx";
export var text = "Quote-module--text--ot1w8";
export var title = "Quote-module--title--aq2lj";
export var white = "Quote-module--white--gigjB";
export var withAvatar = "Quote-module--withAvatar--rhjHD";