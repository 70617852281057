// extracted by mini-css-extract-plugin
export var black = "Slider-module--black--hPgS6";
export var dot = "Slider-module--dot--RHIMJ";
export var dots = "Slider-module--dots--rl55d";
export var gold = "Slider-module--gold--SKc97";
export var goldDark = "Slider-module--goldDark--RwDSP";
export var goldLight = "Slider-module--goldLight--TfKX2";
export var item = "Slider-module--item--LzDyc";
export var ivory = "Slider-module--ivory--obsH5";
export var next = "Slider-module--next--4v-cU";
export var prev = "Slider-module--prev--z4Oyo";
export var root = "Slider-module--root--kbYch";
export var spacingL = "Slider-module--spacingL--DJUk0";
export var spacingM = "Slider-module--spacingM--alFKb";
export var spacingS = "Slider-module--spacingS--GVJfV";
export var spacingXl = "Slider-module--spacingXl--08wlK";
export var spacingXxl = "Slider-module--spacingXxl--AMlHY";
export var spacingXxxl = "Slider-module--spacingXxxl--4m-iH";