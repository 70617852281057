// extracted by mini-css-extract-plugin
export var avatarList = "AvatarCard-module--avatarList--R1mRv";
export var bio = "AvatarCard-module--bio--wjb27";
export var black = "AvatarCard-module--black--upw1l";
export var divider = "AvatarCard-module--divider--zxJjZ";
export var goldDark = "AvatarCard-module--goldDark--nsD3l";
export var goldLight = "AvatarCard-module--goldLight--wbwb-";
export var large = "AvatarCard-module--large--vExuO";
export var name = "AvatarCard-module--name--6e8vY";
export var profile = "AvatarCard-module--profile--5Osnt";
export var root = "AvatarCard-module--root--4O+HI";
export var website = "AvatarCard-module--website--RxSWP";
export var white = "AvatarCard-module--white--zi8s5";