import React, {memo} from 'react';

import classnames from 'classnames';
import {useUID} from 'react-uid';

import * as css from './Inlay.module.css';

export const InlayFromEdge = memo(
  ({
    width,
    svgWidth = 1500,
    radius = 300,
    stroke = 2,
    gap = 16,
    endOffset = 80,
    count = 3,
    height = 800,
    from = 'left',
    image,
    imageWidth = 1440 / 2,
    className,
    style,
    id,
  }) => {
    let borderRadius = 0;
    const cropId = 'crop-' + useUID();
    const gradientId = `paint_linear_${id}`;
    const bezierPos = radius * 0.552284749831;

    // Create paths
    const paths = [];

    for (let i = 0; i < count; i++) {
      const offset = stroke + i * (gap + stroke) + gap;
      const r = radius - i * (gap / 2);
      if (i === count - 1) borderRadius = r;
      let basePath;

      if (from === 'left') {
        basePath = `M 0 ${offset} L ${svgWidth - offset - r} ${offset} C ${
          svgWidth - offset - r + bezierPos
        } ${offset} ${svgWidth - offset} ${offset + r - bezierPos} ${svgWidth - offset} ${offset + r} L ${
          svgWidth - offset
        } ${height - endOffset * (count - (i + 1))}`;
      } else if (from === 'right') {
        basePath = `M ${svgWidth} ${offset} L ${offset + r} ${offset} C ${offset + r - bezierPos} ${offset} ${offset} ${
          offset + r - bezierPos
        } ${offset} ${offset + r} L ${offset} ${height - endOffset * (count - (i + 1))}`;
      }

      paths.push(
        <path
          key={`path-${i}`}
          vectorEffect="non-scaling-stroke"
          d={basePath}
          stroke={`url(#${gradientId})`}
          strokeWidth={stroke}
          strokeMiterlimit="10"
        />,
      );
    }

    let mask;
    if (image) {
      let basePath, imageInlayPath;

      const offset = stroke + count * (gap + stroke) - stroke / 2;
      const r = radius - count * (gap / 2 - stroke);
      if (from === 'left') {
        imageInlayPath = `M ${svgWidth - imageWidth} ${offset} L ${svgWidth - imageWidth} ${height}`;
        basePath = `M ${svgWidth - imageWidth} ${offset} L ${svgWidth - offset - r} ${offset} C ${
          svgWidth - offset - r + bezierPos
        } ${offset} ${svgWidth - offset} ${offset + r - bezierPos} ${svgWidth - offset} ${offset + r} L ${
          svgWidth - offset
        } ${height} L ${svgWidth - imageWidth} ${height} z`;
      } else if (from === 'right') {
        imageInlayPath = `M ${imageWidth} ${offset} L ${imageWidth} ${height}`;
        basePath = `M ${imageWidth} ${offset} L ${offset + r} ${offset} C ${
          offset + r - bezierPos
        } ${offset} ${offset} ${offset + r - bezierPos} ${offset} ${
          offset + r
        } L ${offset} ${height} L ${imageWidth} ${height} z`;
      }
      mask = (
        <clipPath id={cropId}>
          <path d={basePath} />
        </clipPath>
      );

      paths.push(
        <path
          key={`path-image-inlay`}
          vectorEffect="non-scaling-stroke"
          d={imageInlayPath}
          stroke={`url(#${gradientId})`}
          strokeWidth={stroke}
          strokeMiterlimit="10"
        />,
      );
    }
    const radiusStyle =
      from === 'left'
        ? {borderTopRightRadius: borderRadius - gap + stroke * count}
        : {borderTopLeftRadius: borderRadius - gap + stroke * count};

    const positionDiff = (gap + stroke) * count;

    return (
      <>
        <div
          style={{
            width,
            ...style,
          }}
          className={classnames(className, css.root, {
            [css.fromLeft]: from === 'left',
            [css.fromRight]: from === 'right',
            [css.withImage]: image,
          })}
        >
          <svg
            width={svgWidth + stroke}
            height={height}
            viewBox={`0 0 ${svgWidth} ${height}`}
            preserveAspectRatio={from == 'left' ? 'xMaxYMin meet' : 'xMinYMin meet'}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <defs>
              <linearGradient id={gradientId} x1="1" y1="1" x2="948" y2="1" gradientUnits="userSpaceOnUse">
                <stop stopColor="#AD9048" />
                <stop offset="0.532932" stopColor="#D5BB66" />
                <stop offset="1" stopColor="#AD9048" />
              </linearGradient>
              {mask}
            </defs>
            {paths}
          </svg>
        </div>
        {image && (
          <div
            className={classnames(className, css.image)}
            style={{
              position: 'absolute',
              top: `${positionDiff}px`,
              left: from === 'right' ? `${positionDiff}px` : 'auto',
              right: from === 'left' ? `${positionDiff}px` : 'auto',
            }}
          >
            <img src={image} style={{height, ...radiusStyle}} />
          </div>
        )}
      </>
    );
  },
);
