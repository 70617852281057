// extracted by mini-css-extract-plugin
export var black = "Price-module--black--3Lbdu";
export var borderBottom = "Price-module--borderBottom--xHLpa";
export var borderBox = "Price-module--borderBox--rcT7M";
export var borderLeft = "Price-module--borderLeft--DPkyf";
export var borderRight = "Price-module--borderRight--cfsIg";
export var borderTopLeft = "Price-module--borderTopLeft--Rt-p+";
export var borderTopRight = "Price-module--borderTopRight--oBzZf";
export var boxRow = "Price-module--boxRow--cP8I4";
export var disclaimer = "Price-module--disclaimer--Qok1l";
export var goldDark = "Price-module--goldDark--7t+Xy";
export var goldLight = "Price-module--goldLight--F6PWt";
export var price = "Price-module--price--6pOJT";
export var priceRow = "Price-module--priceRow--QHeCL";
export var root = "Price-module--root--zkwdp";
export var type = "Price-module--type--RkTjS";
export var white = "Price-module--white--i0A-8";