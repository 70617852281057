import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as themes from '../styles/themes.module.css';
import {colorVariants, pickVariantWithTheme, themesToOptions} from '../utils';
import * as css from './Logo.module.css';

const Logo = ({width, height, src, srcType = 'img', children, alt = '', variant, theme}) => {
  const logoVariant = variant || pickVariantWithTheme(theme);
  return (
    <figure
      className={classnames(css.root, css[srcType], css[logoVariant], themes[theme])}
      style={{height, width}}
      alt={alt}
    >
      {srcType === 'img' ? <img alt={alt} src={src} /> : children}
    </figure>
  );
};

Logo.interactiveProps = {
  theme: {
    type: 'select',
    options: themesToOptions(themes),
  },
};

Logo.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  src: PropTypes.string,
  srcType: PropTypes.oneOf(['img', 'svg']).isRequired,
  alt: PropTypes.string,
  variant: PropTypes.oneOf(colorVariants),
  theme: PropTypes.oneOf(Logo.interactiveProps.theme.options),
};

export default Logo;
